import axios from "axios";

const API_URL = process.env.REACT_APP_API_BASE_URL;

class AuthService {
  login(email, password) {
    return axios
      .post(API_URL + "/user/login/", {
        email,
        password
      })
      .then(response => {
        if (response.data.jwt) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(email, password, language, birth_date, source) {
    return axios.post(API_URL + "/user/register/", {
      email,
      password,
      language,
      birth_date,
      source
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
}

export default new AuthService();
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";


const Story = () => {
  const history = useHistory();
  const { id } = useParams();
  const { push } = useHistory();
  const [story, SetStory] = useState({});
  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/story/read/?story_id=${id}&family_filter=off`);
        SetStory(data[0]); // leggo il primo el dell'array perché data è un array di oggetti, non un oggetto.
        //console.log(data);
      } catch (err) {
        history.replace("/404"); // in caso di errore restituito dalle API, redirigo verso /404 not found
      }
    };
    fetch();
  }, []);
  return (
    <article className="shadow p-3 mb-5 bg-body rounded">
      <div className="authors">
        {story.authors && (story.authors.map((el) => (
          <Author name={el.first_name + " " + el.last_name} key={el.id_author} />
          )
        ))}      
      </div>
      <h1>{story.title}</h1>
      <figure>
        <img src={story.banner_image} className="figure-img img-fluid rounded" alt={story.title} />
      </figure>
      <p>{story.summary}</p>
      <Episodes />
      <br />
      <button onClick={() => push("/")}>Indietro</button>
    </article>
  );
};

const Author = (props) => {
  const compStyle = {
    marginRight: '0.4rem',
    color: '#000'
  }
  return(
    <span className="badge bg-light" style={compStyle}>{props.name}</span>
  );
}

const Episodes = () => {
  const imgStyle = {
    width: '100%'
  }
  const history = useHistory();
  const { id } = useParams();
  const { push } = useHistory();
  const [episodes, SetEpisodes] = useState({});
  useEffect(() => {
    const fetch = async () => {
      try {
        console.log("before");
        const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/story/episodes/?story_id=${id}&family_filter=off`);        
        SetEpisodes(data); 
        console.log(data);
        console.log("after");
      } catch (err) {
        console.log(err);
      }
    };
    fetch();
  }, []);
  console.log("episodes: " + episodes);
  //setTimeout(() => { console.log("now starting to cycle episodes..."); }, 5000);
  console.log("now starting to cycle episodes...");
  return (
    <>
    {/*  {episodes ? <em>Loading...</em> : <strong>Loaded</strong>} */}
    {/* {episodes && (episodes.map((episode) => (
      <div className="row">
        <img src={episode.cover_image} alt=""  />
        <span>{episode.episode_number}</span>
        <span>{episode.title}</span>
        <span>{episode.free == 1 ? "Gratis" : episode.cost}</span>
      </div>
      )))} */}
      {
        (episodes && episodes.length > 0) ?
        episodes.map((episode) => (
          <div className="row rowEpisode mb-3">
            <div className="col-md-3">
              <img src={episode.cover_image} alt="" style={imgStyle} />
            </div>
            <div className="col-md-3">
              <span>{episode.episode_number}</span>
            </div>
            <div className="col-md-3">
              <span>{episode.title}</span>
            </div>
            <div className="col-md-3">
              <span>{episode.free == 1 ? "Gratis" : episode.price + " coins"}</span>
            </div>
          </div>
          ))
          :
          <div>Nessun capitolo disponibile</div> 
      }
    </>
  );
};

export default Story;
import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import axios from "axios";
import Categories from "../components/Categories";

const Genre = () => {
  const history = useHistory();
  const { id } = useParams();
  const { push } = useHistory();
  const [stories, setStories] = useState([]);
  useEffect(() => {
    const fetch = async () => {
      try {
        console.log(id);
        let url = `${process.env.REACT_APP_API_BASE_URL}/stories//by_category/?lang=it&p=1&family_filter=off&category_id=${id}`;
        if (id == 0) {
          url = `${process.env.REACT_APP_API_BASE_URL}/stories/all/?lang=it&p=1&family_filter=off&q`;
        }
        const { data } = await axios.get(url);        
        setStories(data);
        console.log(data);
      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, []);
  return (
    <>
      <Categories />
      {stories.map((el) => (
        <article key={el.id} className="shadow p-3 mb-5 bg-body rounded">
          <div className="metas mb-3">
            <PublishedEpisodes num={el.published_episodes} />
            {/* <LastPublicationDate pubdate={el.last_publication_date.substring(0,10)} /> */}
            {/* replace con T al posto di spazio, necessario per evitare problemi su safari / ie */}
            <LastPublicationDate pubdate={new Intl.DateTimeFormat("it-IT", {
              year: "numeric",
              month: "long",
              day: "2-digit"
            }).format(Date.parse(el.last_publication_date.replace(" ","T")))} />
           
            
            <Completed status={el.completed} />
          </div>
          <Link to={`/story/${el.id}`}>
            <h1>
              {el.title}
            </h1>
          </Link>
          <p>{el.summary}</p>
          <div className="categories">
              {el.categories && el.categories.map((cat) => (
                //cat.name+" "
                <Category name={cat.name} key={cat.name} />
                )
              )}            
          </div>
        </article>
      ))}
    </>
  );
};

const Completed = (props) => {
  /*
  return(
    <span className="badge bg-info text-dark">{props.status === "1" ? "Completo" : "Incompleto"}</span>
  );
  */
  const status = props.status;
  const completedStyle = {
    opacity: 0.7,
    marginRight: '0.4rem'
  };
  const notcompletedStyle = {
    marginRight: '0.4rem'
  };
  if (status === "1") {
    return(
      <span className="badge bg-info text-dark" style={completedStyle}>Completo</span>
    );
  } else {
    return(
      <span className="badge bg-info text-dark" style={notcompletedStyle}>Incompleto</span>
    );
  }
  
};

const PublishedEpisodes = (props) => {
  const compStyle = {
    marginRight: '0.4rem'
  }
  return(
    <span className="badge bg-danger" style={compStyle}>{props.num} ep.</span>
  );
}

const LastPublicationDate = (props) => {
  const compStyle = {
    marginRight: '0.4rem'
  }
  return(
    <span className="badge bg-warning text-dark" style={compStyle}>{props.pubdate}</span>
  );
}

const Category = (props) => {
  const compStyle = {
    marginRight: '0.4rem'
  }
  return(
    <span className="badge bg-secondary" style={compStyle}>{props.name}</span>
  );
}

export default Genre;
import React from "react";
//import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
/*   NavItem,
  NavDropdown,
  MenuItem, */
  Container
/*   Card,
  Button */
} from "react-bootstrap";

// https://react-bootstrap.github.io/components/navs/
// https://stackoverflow.com/questions/62721457/react-bootstrap-change-link-of-navbar-to-active

function Navigation(props) {
  return (
    <div className="navigation">
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/">Tacotoon</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" as="ul" activeKey={window.location.pathname}>
            <Nav.Item as="li">
              <Nav.Link href="/generi/0" >Generi</Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link href="/coming-soon" >Coming soon</Nav.Link>
            </Nav.Item>
            
            
            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown" as="li">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
  );
}

export default Navigation;
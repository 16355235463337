import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import './App.css';
import Home from "./pages/Home";
import Story from "./pages/Story";
import Genre from "./pages/Genre";
import ComingSoon from "./pages/ComingSoon";
import Login from "./pages/Login";
import NotFound from './pages/NotFound';

import Navigation from "./components/Navigation"
import Footer from "./components/Footer"


const App = () => {
  return (
    <div className="container">
      <Router>
        <Navigation />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/story/:id" component={Story} />
          <Route path="/generi/:id" component={Genre} />
          <Route exact path="/coming-soon" component={ComingSoon} />
          <Route exact path="/login" component={Login} />
          <Route component={NotFound} />
        </Switch>
        <Footer />
      </Router>
    </div>
    
  );
};

export default App;

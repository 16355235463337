import React from "react";
//import { Link } from "react-router-dom";

function Footer(props) {
  return (
    <footer>
      <div className="row">
        <div className="col-md-12">
          Copyright &copy; 2021
        </div>
      </div>
    </footer>
  );
}

export default Footer;
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Navbar, Nav, Container} from "react-bootstrap";

function Categories(props) {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const fetch = async () => {
      try {
        let url = process.env.REACT_APP_API_BASE_URL+"/categories/all/?lang=it&family_filter=on";
        const { data } = await axios.get(url);        
        setCategories(data);
        console.log(data);
      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, []);
  return (
    <div className="categories mb-5">
    <Navbar bg="light" expand="sm">
      <Container>
        <Navbar id="categories-navbar-nav">
          <Nav className="me-auto nav" as="ul" activeKey={window.location.pathname}>
            <Nav.Item as="li">
              <Nav.Link href="/generi/0" >Tutti</Nav.Link>
            </Nav.Item>
            {categories.map((el) => (
            <Nav.Item key={el.id} as="li">
              <Nav.Link href={"/generi/"+el.id} >{el.name}</Nav.Link>
            </Nav.Item>
            ))}
          </Nav>
        </Navbar>
      </Container>
    </Navbar>
    </div>
  );
}

export default Categories;